import './App.css';
import React from 'react';
import User from './components/user';
import Landing from './components/landing';
import Navbar from './components/navbar';
import { Link, Route, Routes } from 'react-router-dom';
import Settings from './components/settings';
import Search from './components/search';
import DockingStations from './components/docking-station';
import { AuthProvider, RequireAuth, ShowLogin } from './components/auth';
import { useSessionStorage } from './components/usesession';
import Login from './components/login';
import AddUser from './components/adduser';

function App ()
{
  const defaultDark = React.useState( window.matchMedia( '(prefers-color-scheme: dark)' ).matches )[ 0 ];
  const darkMode = useSessionStorage( "darkMode", defaultDark )[ 0 ];
  document.querySelector( "html" ).setAttribute( "data-theme", darkMode ? "dark" : "light" );
  return (
    <div className={ darkMode ? "app dark" : "app" }>
      <AuthProvider>
        <div className='left'>
          <Link to="/">
            <div className='logo clickable'>
            </div>
          </Link>
          <hr className='horizontal-divider' />
          <Navbar />
        </div>
        <div className='right'>
          <Routes>
            <Route path="/" element={ <Landing /> } />
            <Route path="/user" element={ <RequireAuth admin={ false } ><User /></RequireAuth> } />
            <Route path="/login" element={ <ShowLogin><Login /></ShowLogin> } />
            <Route path="/adduser" element={ <RequireAuth admin={ true }><AddUser /></RequireAuth> } />
            <Route path="/search" element={ <RequireAuth admin={ false }><Search /></RequireAuth> } />
            <Route path="/settings" element={ <Settings /> } />
            <Route path="/docking-stations" element={ <RequireAuth admin={ false }><DockingStations /></RequireAuth> } />
          </Routes>
        </div>
      </AuthProvider>
    </div>
  );
}

export default App;