import Progress from "./progress";
import TopNavBar from "./topnavbar";

function Landing() {
    return (
        <>
            <TopNavBar children={<h2>Home page</h2>} />
            <div className='main'>
                <Progress />
            </div>
        </>
    )
}

export default Landing;