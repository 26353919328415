import React, { InputHTMLAttributes } from "react";
import '../css/select.css';
import colors from "./colors";
type OptionType = {
    value: string,
    title: string;
};
interface IProps extends InputHTMLAttributes<HTMLSelectElement>
{
    color?: colors,
    width?: string,
    label?: string,
    type?: string,
    options?: OptionType[];
}

class Select extends React.Component<IProps & React.HTMLProps<HTMLSelectElement>, {}> {

    render ()
    {
        let className = "select-container", label = <span>{ this.props.label }</span>;
        if ( this.props.color )
            className += " " + this.props.color;
        return (
            <label className={ className } style={ { "width": this.props.width } }>
                { label }
                <select defaultValue={ this.props.options[ 0 ].value }{ ...this.props }  >
                    { this.props.options.map( option =>
                    {
                        return ( <option value={ option.value } key={ option.value }>{ option.title }</option> );
                    } ) }
                </select>
            </label>
        );

    }
}

export default Select;