import { ChangeEvent } from "react";
import Input from "./input";
import TopNavBar from "./topnavbar";
import { useSessionStorage } from "./usesession";



function Settings() {
    let [darkMode, setDarkMode] = useSessionStorage("darkMode", undefined);
    function toggleDarkmode(e: ChangeEvent<HTMLInputElement>) {
        setDarkMode(e.currentTarget.checked);
        document.querySelector("html").setAttribute("data-theme", e.currentTarget.checked ? "dark" : "light");

    }
    return (
        <>
            <TopNavBar children={<h2>Settings</h2>} />
            <div className='main'>
                <div className="settings">
                    <Input type="checkbox" label="Dark mode" checked={darkMode} onChange={(e: ChangeEvent<HTMLInputElement>) => { toggleDarkmode(e) }} />
                </div>
            </div>
        </>
    )
}

export default Settings;