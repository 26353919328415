
  import Axios from 'axios'
  import { User } from 'oidc-client-ts'

  export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export const getHeaders = async () => {
    const accessToken = await getAccessToken();
    console.log(accessToken);
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : "";
    return headers;
  };

  export const getAccessToken = async () => {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${process.env.NEXT_PUBLIC_IDENTITY_URL}:${process.env.NEXT_PUBLIC_IDENTITY_CLIENT_ID}`
    );
    if (!oidcStorage) {
      return null;
    }
    return User.fromStorageString(oidcStorage).access_token;
  };
 
  
  let AxiosInstance = Axios.create({
  baseURL: 'https://www.reloadtech.hu', 
  //baseURL: 'https://localhost:44371', 
})

AxiosInstance.defaults.headers.post["Content-Type"] = "application/json";
AxiosInstance.defaults.xsrfCookieName = "XSRF-TOKEN";
AxiosInstance.defaults.xsrfHeaderName = "RequestVerificationToken";
AxiosInstance.defaults.withCredentials = true;
AxiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

AxiosInstance.interceptors.response.use(async function(config: any) {
  config.headers = await getHeaders();
  return config;
})

export default AxiosInstance