import React, { useState } from 'react';
import Button from './button';
import colors from './colors';
import Input from './input';
import '../css/login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './auth';
import { LocationState } from './location-state';
import Progress from './progress';
import TopNavBar from './topnavbar';

function Login() {
  let [error, setError] = useState(false);
  let [progress, setProgress] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let state = location.state as LocationState || { from: { pathname: '/' } } as LocationState;
  let auth = useAuth();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;
    let password = formData.get("password") as string;

    setProgress(true);
    auth.signin(email, password, (authenticated) => {
      setProgress(false);
      setError(!authenticated);
      if (authenticated)
        navigate(state.from.pathname + "/" + auth.user.id, { replace: true });
    })

  }

  return (
    <>
      <TopNavBar children={<h2>Login</h2>} />
      <div className='main'>
        {progress ? <div className='progress-container'><Progress /></div> : null}
        {state.from.pathname !== '/' && <p>You must log in to view the page at {state.from.pathname}</p>}
        {error && <p>Wrong email or password!</p>}
        <form className='login' onSubmit={handleSubmit}>
          <Input autoFocus label="Email address" name="email" width="200px" color={error ? colors.red : colors.green} />
          <Input label="Password" type="password" name="password" width="200px" color={error ? colors.red : colors.green} />
          <Button type="submit" text="Sign in" color={colors.green} />
        </form>
      </div>

    </>
  );
}

export default Login;