import {  useState } from "react";

export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const updatedSetValue = newValue => {
      try {
        window.localStorage.setItem(key, JSON.stringify(newValue));
      } catch (err) {}
      setValue(newValue ?? defaultValue);
    };
  
  return [value, updatedSetValue];
}