import { faCircleNotch, faGear, faRightToBracket, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "../css/navbar.css";
import { useAuth } from "./auth";
import dummy from '../dummyuser.jpg';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

function Navbar() {
    let auth = useAuth();

    return (
        <div className='left-navbar'>
            <div className='left-nav-icons'>
              <div className='left-nav-icon'>
                <Link to={"/user"}>
                  {auth.authenticated?
                    auth.user.profilePicture?
                      <img src={dummy} className="profilePicture" alt={auth.user.displayedName} />
                      :
                      <FontAwesomeIcon icon={faUser as IconProp} className='icon clickable' fixedWidth/>
                    :
                    <FontAwesomeIcon icon={faRightToBracket as IconProp} className='icon clickable' fixedWidth/>
                  }
                </Link>
              </div>
              <div className='left-nav-icon' >
                <Link to="/docking-stations">
                  <FontAwesomeIcon icon={faCircleNotch as IconProp} className='icon clickable' fixedWidth/>
                </Link>
              </div>
              <div className='left-nav-icon' >
                <Link to="/search">
                  <FontAwesomeIcon icon={faSearch as IconProp} className='icon clickable' fixedWidth/>
                </Link>
              </div>
              <div className='left-nav-icon' >
                <Link to="/settings">
                  <FontAwesomeIcon icon={faGear as IconProp} className='icon clickable' fixedWidth/>
                </Link>
              </div>
            </div>
          </div>
    );
}

export default Navbar;