import React, { InputHTMLAttributes } from "react";
import '../css/input.css';
import colors from "./colors";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    color?: colors,
    width?: string,
    label?: string,
    type?: string,
    error?: boolean,
    errorMessage?: string
}

class Input extends React.Component<IProps & React.HTMLProps<HTMLInputElement>, {}> { 
    className: string;
    label: JSX.Element;
    style: { width: string; };
    sortProps<Props extends IProps & React.HTMLProps<HTMLInputElement>>(
        props: Props
      ): Omit<Props, "color" | "width" | "label" | "error" | "errorMessage"> {
        const { color, width, label, error, errorMessage, ...rest } = props;
        if(this.props.type === "checkbox") {
            this.className = "checkbox-container";
        } else {
            this.className = "input-container";
        }
        if(error) {
            this.className += " " + colors.red;
        } else if(color){
            this.className += " " + color;
        }
        this.label = <span>{label}</span>;
        this.style = {"width":width};
        return rest;
      }
    render() {
         
        const inputprops = this.sortProps(this.props);
        
        return (
                <label className={this.className} style={this.style}> 
                    {this.props.type !== "checkbox" && this.label} 
                    <div className="input">
                        <input {...inputprops}  />
                        {this.props.error && 
                            <span className="error-message">{this.props.errorMessage}</span>}
                    </div>
                    {this.props.type === "checkbox" &&  this.label} 
                    
                </label>
        )
        
    }
}

export default Input;