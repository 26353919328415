import { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./auth";
import Button from "./button";
import colors from "./colors";
import { LocationState } from "./location-state";
import Progress from "./progress";
import TopNavBar from "./topnavbar";
import { UserList } from "./userslist";

export function User() {
    let [progress, setProgress] = useState(false);
    let balint = false;
    let auth = useAuth();
    let navigate = useNavigate();
    let location = useLocation();
    let state = location.state as LocationState || { from: { pathname: '/' } } as LocationState;
    if (auth.authenticated) {
        let addUserButton = (
            <Button type="button" text="Add user" color={colors.ligthgray} onClick={() => { navigate("/adduser", { replace: true, state: { from: state.from } }); }} />
        );
        return (
            <>

                <TopNavBar children={<h2>Welcome {auth.user.displayedName}</h2>} />
                <div className='main'>
                    <Outlet />
                    {progress ? <div className='progress-container'><Progress /></div> : null}
                    {auth.user.privilege === "admin" && balint && addUserButton}
                    <Button color={colors.green} text='Kijelentkezés'
                        onClick={
                            () => {
                                setProgress(true);
                                auth.signout(() => {
                                    setProgress(false);
                                    navigate("/", { replace: true })
                                })
                            }
                        } />
                    {auth.user.privilege === "admin" && <UserList />}
                </div>

            </>
        )

    }
    return (
        <>
            <Navigate to="/login" state={{ from: location }} replace />;
        </>
    )
}

export default User;