import React, { InputHTMLAttributes } from "react";
import '../css/autocomplete.css';
import colors from "./colors";
type OptionType = {
    id: string,
    title: string;
};
interface IProps extends InputHTMLAttributes<HTMLSelectElement>
{
    color?: colors,
    width?: string,
    label?: string,
    type?: string,
    options: OptionType[];
    icon?: any;
    changeCallback?: ( id: string ) => void;
}
interface IState
{
    autoCompleteList: OptionType[];
    selectedOption: OptionType;
    showList: boolean;
    activeAutoCompleteIndex: number;
}

class AutoCompleteSelect extends React.Component<IProps & React.HTMLProps<HTMLSelectElement>, IState> {

    /**
     *
     */
    constructor ( props: ( IProps & React.HTMLProps<HTMLSelectElement> ) | Readonly<IProps & React.HTMLProps<HTMLSelectElement>> )
    {
        super( props );
        this.state = {
            autoCompleteList: this.props.options,
            selectedOption: { id: "", title: "" },
            showList: false,
            activeAutoCompleteIndex: 0
        };
    }
    componentDidUpdate ( _prevProps, prevState: IState ): void
    {
        if ( prevState.selectedOption.id !== this.state.selectedOption.id )
        {
            this.callback( this.state.selectedOption.id );
        }
    }


    onchange ( search: string )
    {
        let newlist = search === "" ? this.props.options : this.props.options.filter( x => x.title.toLocaleLowerCase().includes( search.toLocaleLowerCase() ) );

        let newSelected = this.props.options.find( x => x.title.toLocaleLowerCase() === search.toLocaleLowerCase() );
        if ( newSelected )
        {
            this.setState( { selectedOption: newSelected, showList: false } );

        } else
        {
            this.setState( { selectedOption: { id: "", title: search }, autoCompleteList: newlist, showList: true, activeAutoCompleteIndex: 0 } );
        }
    }

    autoComplete = ( element: EventTarget & HTMLLIElement ) =>
    {

        this.setState( { selectedOption: { id: element.getAttribute( "attr-id" ), title: element.getAttribute( "attr-title" ) }, showList: false } );

    };

    callback ( id )
    {
        if ( this.props.changeCallback )
        {
            this.props.changeCallback( id );
        }
    }

    lostFocus = () =>
    {
        this.setState( { showList: false } );
    };

    onFocus = () =>
    {
        if ( this.state.autoCompleteList.length > 0 )
        {
            this.setState( { showList: true } );
        }
    };

    onKey = ( e ) =>
    {
        if ( e.keyCode === 13 && this.state.autoCompleteList.length > 0 )
        {
            this.setState( { selectedOption: this.state.autoCompleteList[ this.state.activeAutoCompleteIndex ], autoCompleteList: [], showList: false } );

        } else if ( e.keyCode === 13 && this.state.selectedOption.id )
        {
            this.callback( this.state.selectedOption.id );
        } else if ( e.keyCode === 38 )
        {
            if ( this.state.activeAutoCompleteIndex > 0 )
            {
                this.setState( { activeAutoCompleteIndex: this.state.activeAutoCompleteIndex - 1 } );
            }
            e.preventDefault();
            e.stopPropagation();
        } else if ( e.keyCode === 40 )
        {
            if ( this.state.autoCompleteList.length > this.state.activeAutoCompleteIndex + 1 )
            {
                this.setState( { activeAutoCompleteIndex: this.state.activeAutoCompleteIndex + 1 } );
            }
            e.preventDefault();
            e.stopPropagation();
        } else if ( e.keyCode === 46 )
        {
            this.setState( {
                autoCompleteList: this.props.options,
                selectedOption: { id: "", title: "" },
                activeAutoCompleteIndex: 0
            } );
        }
    };

    render ()
    {

        let className = "search-container";
        if ( this.props.type === "inline" )
        {
            className += " inline";
        }
        let label = <span>{ this.props.label }</span>;
        if ( this.props.color )
            className += " " + this.props.color;
        let display = this.state.showList ?
            { display: "block" } :
            {};
        return (

            <label className={ className }
                style={ { "width": this.props.width } }>
                { label }

                <div className="search_bar" style={ { "width": this.props.width } }>
                    { this.props.icon }
                    <input style={ { "width": "calc(" + this.props.width + ( this.props.icon ? " - 1.25em" : "" ) + ")" } }
                        className="search" placeholder={ this.props.placeholder }
                        onClick={ () => { this.onFocus(); } }
                        onKeyDown={ ( e ) => { this.onKey( e ); } }
                        onChange={ ( e ) => { this.onchange( e.currentTarget.value ); } }
                        value={ this.state.selectedOption?.title }
                        onBlur={ () => { setTimeout( () => this.lostFocus(), 100 ); } } />
                    <div className="autocomplete_holder"
                        style={ { "width": "calc(" + this.props.width + ( this.props.icon ? " - 1.25em" : "" ) + ")" } }>
                        <div className="autocomplete" style={ display }>
                            { this.state.autoCompleteList.map( ( item, index ) =>
                            {
                                return (
                                    <li className={ this.state.activeAutoCompleteIndex === index ? "autocomplete_li active" : "autocomplete_li" } key={ item.id }
                                        attr-id={ item.id } attr-title={ item.title }
                                        onClick={ ( e ) => { this.autoComplete( e.currentTarget ); } }>{ item.title }</li>
                                );
                            } )
                            }

                        </div>
                    </div>
                </div>
            </label>
        );

    }
}

export default AutoCompleteSelect;