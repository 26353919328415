import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useAuth } from "./auth";
import Progress from "./progress";
import "../css/stationunitinfo.css";
import colors from "./colors";
import Button from "./button";
import AutoCompleteSelect from "./autocompleteselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "./tooltip";
import { Statuses } from "src/utils/dataprov";

type StationUnitInfoType = {
    rollerDockedIn: string;
    rollerDockedOut: string;
    kiloWattHour: string;
    consumerId: string;
};

const StationUnitInfo = ( { stationUnitId, status, consumers, callback } ) =>
{
    let [ toDate, setToDate ] = useState( new Date() );
    let [ info, setInfo ] = useState<{ stationUnitId: string, transaction: StationUnitInfoType[]; }>();
    let [ fromDate, setFromDate ] = useState( new Date( toDate ) );
    let [ consumer, setConsumer ] = useState( null );
    let [ chartData, setChartData ] = useState( { time: [], consumed: [] } );
    let [ isFetched, setFetched ] = useState( false );
    let [ refreshedTime, setRefreshedTime ] = useState<Date>();
    let [ selectedInterval, setSelectedInterval ] = useState( "month" );
    let [ statusDummy, setStatusDummy ] = useState( status );
    let [ error, setError ] = useState<string>( null );
    let auth = useAuth();
    const [ options, setOptions ] = useState( {} );


    useEffect( () =>
    {
        fromDate.setMonth( toDate.getMonth() - 1 );
    }, [] );  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>
    {
        console.log( fromDate + " ----  " + toDate );
        refreshInfos();
        setRefreshedTime( new Date() );
    }, [ toDate, fromDate ] ); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>
    {
        console.log( "consumer: " + consumer );
        refreshInfos();
        setRefreshedTime( new Date() );
    }, [ consumer ] ); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>
    {
        callback( stationUnitId, statusDummy );
    }, [ statusDummy ] ); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect( () =>
    {
        let data = { time: [], consumed: [] };
        info?.transaction?.forEach( ( dockingInfo, index ) =>
        {
            let kwh = Number.parseInt( dockingInfo.kiloWattHour );
            let inDate = new Date( dockingInfo.rollerDockedIn );
            let outDate = new Date( dockingInfo.rollerDockedOut );

            let dif = ( outDate.valueOf() - inDate.valueOf() ) / 60000;
            data.time[ index ] = dif;
            data.consumed[ index ] = kwh;
        } );
        setChartData( data );
    }, [ info ] ); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>
    {
        setOptions( {
            chart: {
                zoomType: 'xy',
                width: 600
            },
            title: {
                text: 'Comsumed kWH per useage'
            },
            xAxis: [ {
                labels: {
                    enabled: false
                },
                crosshair: true
            } ],
            yAxis: [ {
                title: {
                    text: 'Consumed',
                    style: {
                        color: Highcharts.getOptions().colors[ 1 ]
                    }
                },
                labels: {
                    format: '{value} kWH',
                    style: {
                        color: Highcharts.getOptions().colors[ 1 ]
                    }
                }
            }, {
                labels: {
                    format: '{value} Minutes',
                    style: {
                        color: Highcharts.getOptions().colors[ 0 ]
                    }
                },
                title: {
                    text: 'Time',
                    style: {
                        color: Highcharts.getOptions().colors[ 0 ]
                    }
                },
                opposite: true
            } ],
            tooltip: {
                formatter: function ()
                {
                    let time = this.points[ 0 ].y;
                    return this.x + '.<br/>' + this.points[ 0 ].series.name + ': ' +
                        ( time >= 60 ? Math.trunc( time / 60 ) + ' hours ' + Math.trunc( time % 60 ) + ' minutes ' : Math.trunc( time ) + ' minutes ' ) + ( Math.trunc( ( time - Math.trunc( time ) ) * 60 ) + " seconds" ) + "</br>"
                        + this.points[ 1 ].series.name + ': ' + ( this.points[ 1 ].y + "kWH" );

                },
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 120,
                verticalAlign: 'top',
                y: 100,
                floating: true,
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)'
            },
            series: [ {
                name: 'Hours',
                type: 'column',
                yAxis: 1,
                data: chartData.time,
                tooltip: {
                    valueSuffix: ' Minutes'
                }
            }, {
                name: 'Consumed',
                type: 'spline',
                data: chartData.consumed,
                tooltip: {
                    valueSuffix: ' kWH'
                }
            } ]
        } );
    }, [ chartData ] );  // eslint-disable-line react-hooks/exhaustive-deps


    function refreshInfos ()
    {
        auth.getStationUnit( stationUnitId, toDate, fromDate, consumer, ( stationUnit ) =>
        {
            setInfo( stationUnit );
            setInterval( () =>
            {

                setFetched( true );
            }, 500 );
            setRefreshedTime( new Date() );
        } );
    }
    function showToday ()
    {
        let today = new Date();
        today = new Date( today.getFullYear(), today.getMonth(), today.getDate() );
        setToDate( new Date() );
        setFromDate( today );
        setSelectedInterval( "today" );
    }

    function showLastWeek ()
    {
        let weekBefore = new Date();
        weekBefore.setMonth( weekBefore.getMonth(), weekBefore.getDay() - 7 );
        setToDate( new Date() );
        setFromDate( weekBefore );
        setSelectedInterval( "week" );
    }

    function showLastMonth ()
    {
        let monthBefore = new Date();
        monthBefore.setMonth( monthBefore.getMonth() - 1 );
        setToDate( new Date() );
        setFromDate( monthBefore );
        setSelectedInterval( "month" );
    }

    function showLastTwoMonth ()
    {
        let monthBefore = new Date();
        monthBefore.setMonth( monthBefore.getMonth() - 2 );
        setToDate( new Date() );
        setFromDate( monthBefore );
        setSelectedInterval( "twomonth" );
    }

    function filterByConsumer ( consumer: string )
    {
        setConsumer( consumer );
    }

    function refresh ()
    {
        setToDate( new Date() );
    }

    function handleLock ()
    {
        let newStatus = statusDummy === "free" ? "occupied" : "free";
        if ( newStatus === Statuses.occupied )
        {
            auth.closeUnit( stationUnitId, ( success, message ) =>
            {

                if ( success )
                {
                    setStatusDummy( newStatus );
                    setError( null );
                } else
                {
                    setError( message );
                }
                console.log( message );
            } );
        } else
        {
            auth.openUnit( stationUnitId, ( success, message ) =>
            {
                console.log( message );
                if ( success )
                {
                    setStatusDummy( newStatus );
                    setError( null );
                } else
                {
                    setError( message );
                }
            } );

        }
    }

    if ( !isFetched )
    {
        return (
            <Progress />
        );
    }
    return (

        <div className="station-unit">
            { info ?
                <>
                    <div className="filter-wrapper">
                        <div className="refreshed-time">
                            Last refreshed: { refreshedTime.toLocaleString() } <Button color={ colors.ligthgray } text="Refresh" onClick={ () => { refresh(); } } />

                            <Tooltip tooltipText="Unlock" className="lock-unlock">
                                <Button color={ statusDummy === "fault" ? colors.red : statusDummy === "occupied" ? colors.ligthgray : colors.green } onClick={ () => { handleLock(); } }>
                                    <FontAwesomeIcon icon={ statusDummy === "fault" || statusDummy === "occupied" ? faLock as IconProp : faLockOpen as IconProp } className='icon clickable' fixedWidth />
                                </Button>
                            </Tooltip>
                        </div>
                        <div className="chart-filters">
                            <div className="filter">
                                <Button color={ selectedInterval === "today" ? colors.green : colors.ligthgray } text="Last Day" onClick={ () => { showToday(); } } />
                            </div>
                            <div className="filter">
                                <Button color={ selectedInterval === "week" ? colors.green : colors.ligthgray } text="Last Week" onClick={ () => { showLastWeek(); } } />
                            </div>
                            <div className="filter">
                                <Button color={ selectedInterval === "month" ? colors.green : colors.ligthgray } text="Last Month" onClick={ () => { showLastMonth(); } } />
                            </div>
                            <div className="filter">
                                <Button color={ selectedInterval === "twomonth" ? colors.green : colors.ligthgray } text="Last 2 Month" onClick={ () => { showLastTwoMonth(); } } />
                            </div>
                            <div className="filter">
                                <AutoCompleteSelect options={ consumers } changeCallback={ filterByConsumer } label="Consumer:" />
                            </div>
                        </div>
                    </div>
                    <hr className="dotted"></hr>
                    <div className={ error ? "error" : "error hidden" } >

                        <h2><FontAwesomeIcon icon={ faExclamationCircle as IconProp } /> { error } <FontAwesomeIcon icon={ faExclamationCircle as IconProp } /></h2>
                        <hr className="dotted"></hr>
                    </div>

                    <div className="chart">
                        <HighchartsReact
                            constructorType={ "chart" }
                            highcharts={ Highcharts }
                            allowChartUpdate={ true }
                            options={ options } />
                    </div></>
                : <>
                    <h1>No data from server!</h1>
                </> }
        </div >
    );
};

export default StationUnitInfo;