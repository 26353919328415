import React, { ButtonHTMLAttributes } from "react";
import '../css/button.css';
import colors from "./colors";


interface IProps extends ButtonHTMLAttributes<HTMLButtonElement>
{
    text?: string;
    color?: colors;
    children?: JSX.Element;
}

class Button extends React.Component<IProps & React.HTMLProps<HTMLButtonElement>, {}> {

    render ()
    {
        return (
            <button { ...this.props } className={ "button " + this.props.color + " " + this.props.className } >{ this.props.children ? this.props.children : this.props.text }</button>
        );
    }
}

export default Button;