import React, { useEffect, useState } from "react";
import { DockingStationType } from "src/utils/dataprov";
import { useAuth } from "./auth";
import Progress from "./progress";
import "../css/dockingstation.css";
import TopNavBar from "./topnavbar";

function DockingStations ()
{
    let [ dockingStations, setDockingStations ] = useState<DockingStationType[]>( [] );
    let auth = useAuth();
    let [ dataLoaded, setDataLoaded ] = useState( false );

    useEffect( () =>
    {
        auth.getDockingStations( ( list ) =>
        {
            setDockingStations( list );
            setDataLoaded( true );
        } );
    }, [] );  // eslint-disable-line react-hooks/exhaustive-deps
    useEffect( () =>
    {
        if ( dockingStations?.length > 0 )
            setDataLoaded( true );
    }, [ dockingStations ] );


    if ( !dataLoaded )
    {
        return ( <><Progress /></> );
    } else
    {
        return (
            <><TopNavBar  >
                <h2>Map</h2>
            </TopNavBar>
                <div style={ { display: "flex", height: "100%" } }>
                    <iframe
                        width="600"
                        height="450"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAn33wijdxDOilinNe2yRKSFrHo0k2MJSk&q=Budapest">
                    </iframe>

                </div></>
        );
    }
}





export default DockingStations;

