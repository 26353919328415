import React, { useEffect, useState } from "react";
import { useAuth, UserType } from "./auth";
import Progress from "./progress";
import '../css/userlist.css';

export function UserList ()
{

    let [ users, setUsers ] = useState<UserType[]>( [] );
    let [ isFetched, setIsFetched ] = useState( false );
    let auth = useAuth();
    useEffect( () =>
    {
        auth.getUsers( ( u ) =>
        {
            setUsers( u );
            setIsFetched( true );
            console.log( "users added" );
        } );

    }, [] );  // eslint-disable-line react-hooks/exhaustive-deps


    if ( !isFetched )
    {
        return ( <><Progress /></> );
    }
    return (
        <>
            <div className="users-list">
                {
                    users.map( ( u ) =>
                    {
                        return (
                            <User u={ u } key={ u.id } />
                        );
                    } )
                }
            </div>
        </>
    );
}


function User ( u: { u: UserType | ( () => UserType ); } )
{
    const [ user, setUser ] = useState<UserType>( u.u );
    const [ isExpanded, setExpanded ] = useState( false );
    let auth = useAuth();
    function getRole ( user: UserType ): void
    {
        if ( user.privilege === null )
            auth.getUserRole( user.id.toString(), ( role ) =>
            {
                setUser( { ...user, privilege: role } );
            } );
    }
    return (
        <div className={ isExpanded ? "user expanded" : "user" }>
            <div className="header" onClick={ () => { getRole( user ); setExpanded( !isExpanded ); } }>
                <div>{ user.displayedName }</div>
                <div>{ user.email }</div>
            </div>
            <div className="user-info">
                <div>Role: { user.privilege }</div>
                <div>Status: { user.isActive ? "Active" : "Inactive" }</div>
            </div>
        </div>

    );
}