import React from 'react';
import '../css/tooltip.css';
import colors from "./colors";


interface IProps 
{
    tooltipText: string;
    color?: colors;
    children: JSX.Element;
    className: string;
}

class Tooltip extends React.Component<IProps, {}> {

    render ()
    {
        return (
            <div className={ "tooltip " + this.props.color + " " + this.props.className }>
                { this.props.children }
                <span className="tooltiptext">{ this.props.tooltipText }</span>
            </div>
        );
    }
};

export default Tooltip;