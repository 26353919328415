import progress from '../rollin-circle.png';

function Progress() {
    return (
        <>
            <img src={progress} className="progress" alt="progress" />
        </>
    )
}

export default Progress;