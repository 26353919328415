import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { authProvider } from "src/authentication/authprov";
import { dataProvider, DockingStationType, SUStatusesByDS } from "src/utils/dataprov";
import { useLocalStorage } from "./uselocal";

export function useAuth ()
{
  return React.useContext( AuthContext );
}

export type UserType = {
  id: number,
  email: string,
  displayedName: string,
  profilePicture: string,
  privilege: string,
  isActive: boolean;
};

export interface AuthContextType
{
  authenticated: boolean;
  user: UserType;
  checkToken: () => void;
  getUsers: ( callback: ( users: UserType[] ) => void ) => void;
  emailAvailable: ( email: string, callback: ( available: boolean ) => void ) => void;
  addUser: ( displayedName: string, privilege: string, email: string, password: string, callback: ( added: boolean ) => void ) => void;
  signin: ( email: string, password: string, callback: ( authenticated: boolean ) => void ) => void;
  signout: ( callback: VoidFunction ) => void;
  getDockingStations: ( callback: ( list: DockingStationType[] ) => void ) => void;
  getStationUnit: ( stationUnitId: string, toDate: Date, fromDate: Date, consumer: string, callback: ( stationUnit ) => void ) => void;
  getStationUnitStatus: ( stationUnitId: string, callback: ( statuses: SUStatusesByDS ) => void ) => void;
  getConsumedKWHByConsumer: ( selectedConsumer: string, callback: ( kWH: number ) => void ) => void;
  getConsumedKWHOnUnit: ( unitId: string, callback: ( kWH: number ) => void ) => void;
  getUserRole: ( id: string, callback: ( role: string ) => void ) => void;
  openUnit: ( unitId: string, callback: ( success: boolean, message: string ) => void ) => void;
  closeUnit: ( unitId: string, callback: ( success: boolean, message: string ) => void ) => void;
}

export let AuthContext = React.createContext<AuthContextType>( null! );

export function RequireAuth ( { admin, children }: { admin: boolean, children: JSX.Element; } )
{
  let auth = useAuth();
  let location = useLocation();

  if ( !auth.authenticated )
  {
    return <Navigate to="/login" state={ { from: location } } replace />;
  }
  console.log( " - " + admin );

  if ( auth.checkToken && admin && auth.user.privilege !== "admin" )
  {
    return <Navigate to="/" state={ { from: location } } replace />;
  }

  return children;
}

export function ShowLogin ( { children }: { children: JSX.Element; } )
{
  let auth = useAuth();
  let location = useLocation();
  if ( auth.authenticated )
  {
    return <Navigate to={ "/user" } state={ { from: location } } replace />;
  }
  return children;
}

export function AuthProvider ( { children }: { children: React.ReactNode; } )
{
  let [ user, setUser ] = useLocalStorage( "user", { email: null, displayedName: null, profilePicture: null, privilege: null } );
  let [ authenticated, setAuthenticated ] = useState( false );

  React.useEffect( () =>
  {

    checkToken();

  }, [] );  // eslint-disable-line react-hooks/exhaustive-deps


  let emailAvailable = ( email: string, callback: ( available: boolean ) => void ) =>
  {
    authProvider.getUserByEmail( email, ( user ) =>
    {
      console.log( user );
      callback( user.id ? false : true );
    } );
  };

  let getUsers = ( callback: ( users: UserType[] ) => void ) =>
  {
    authProvider.getUsers( callback );
  };

  let checkToken = () =>
  {
    authProvider.isAuthenticated( ( auth ) =>
    {
      setAuthenticated( auth );
      if ( !auth )
      {
        setUser( { email: null, displayedName: null, profilePicture: null, privilege: null } );
      }
      else
      {
        authProvider.userInfo( ( data ) =>
        {
          setUser( data );
        } );
      }
      console.log( "Authenticated: " + auth );
    } );

  };

  let addUser = ( displayedName: string, privilege: string, email: string, password: string, callback: ( added: boolean ) => void ) =>
  {
    return authProvider.adduser( displayedName, privilege, email, password, callback );
  };

  let login = ( email: string, password: string, callback: ( success: boolean ) => void ) =>
  {
    return authProvider.login( email, password, ( success ) =>
    {
      setAuthenticated( success );
      callback( success );
      if ( success )
      {
        console.log( "Logged in " + email );
        authProvider.userInfo( ( data ) =>
        {
          setUser( data );
        } );
      }
    } );
  };

  let logout = ( callback: VoidFunction ) =>
  {
    return authProvider.logout( ( success ) =>
    {
      setAuthenticated( !success );
      setUser( { email: null, displayedName: null, profilePicture: null, privilege: null } );
      console.log( "Logged out." );
      callback();
    } );
  };

  let getDockingStations = ( callback: ( list: DockingStationType[] ) => void ) =>
  {
    dataProvider.getAllStation( callback );
  };

  let getStationUnit = ( stationUnitId: string, toDate: Date, fromDate: Date, consumer: string, callback: ( stationUnit ) => void ) =>
  {
    dataProvider.getStationUnit( stationUnitId, toDate, fromDate, consumer, callback );
  };

  let getStationUnitStatus = ( stationUnitId: string, callback: ( statuses: SUStatusesByDS ) => void ) =>
  {
    dataProvider.getStationUnitStatus( stationUnitId, callback );
  };

  let getConsumedKWHByConsumer = ( selectedConsumer: string, callback: ( kWH: number ) => void ) =>
  {
    dataProvider.getConsumedKWHByConsumer( selectedConsumer, callback );
  };

  let getConsumedKWHOnUnit = ( unitId: string, callback: ( kWH ) => void ) =>
  {
    dataProvider.getConsumedKWHOnUnit( unitId, callback );
  };

  let getUserRole = ( id: string, callback: ( role: string ) => void ) =>
  {
    authProvider.getUserRole( id, callback );
  };

  let openUnit = ( unitId: string, callback: ( success: boolean, message: string ) => void ) =>
  {
    dataProvider.openUnit( unitId, callback );
  };

  let closeUnit = ( unitId: string, callback: ( success: boolean, message: string ) => void ) =>
  {
    dataProvider.closeUnit( unitId, callback );
  };

  let value = {
    authenticated, user,
    checkToken: checkToken,
    getUsers: getUsers,
    emailAvailable: emailAvailable,
    addUser: addUser,
    signin: login,
    signout: logout,
    getDockingStations: getDockingStations,
    getStationUnit: getStationUnit,
    getStationUnitStatus: getStationUnitStatus,
    getConsumedKWHByConsumer: getConsumedKWHByConsumer,
    getConsumedKWHOnUnit: getConsumedKWHOnUnit,
    getUserRole: getUserRole,
    openUnit: openUnit,
    closeUnit: closeUnit
  };

  return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}