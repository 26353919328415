import api from "./api";



export const dataProvider = {
    getAllStation ( callback: ( list: DockingStationType[] ) => void )
    {
        api.get( '/api/app/docking-station' )
            .then( ( response ) =>
            {
                let data = response.data;
                let list = data as DockingStationType[];

                callback( list );
                console.log( "server response: " + data.description );
            },
                ( error ) =>
                {
                    callback( null );
                    console.log( "Server error: " + error );
                } );

    },
    getStationUnit ( stationUnitId: string, toDate: Date, fromDate: Date, consumer: string, callback: ( stationUnit ) => void )
    {
        if ( !toDate )
        {
            toDate = new Date();

        }
        if ( !fromDate )
        {
            fromDate = new Date( toDate.setMonth( toDate.getMonth() - 1 ) );
        }

        console.log( "to: " + toDate );
        console.log( "from: " + fromDate );
        let requestparams = "?";
        if ( fromDate )
        {
            requestparams += "fromDate=" + fromDate.toISOString() + "&";
        } else if ( toDate )
        {
            requestparams += "toDate=" + toDate.toISOString() + "&";
        }
        if ( consumer )
        {
            requestparams += "consumer=" + consumer;
        }

        api.get( '/api/app/docking-station/' + stationUnitId + "/station-unit" + requestparams )
            /*api.get( '/api/app/docking-station/' + stationUnitId + "/station-unit" )*/
            .then( ( response ) =>
            {
                console.log( response.data );
                callback( response.data );
            }, ( error ) =>
            {
                callback( null );
                console.log( "Server error: " + error );
            }
            );
    },
    getStationUnitStatus ( dockingStationId: string, callback: ( statuses: SUStatusesByDS ) => void )
    {
        api.get( '/api/app/docking-station/station-units-status-by-docking-station-id/' + dockingStationId )
            .then( ( response ) =>
            {
                console.log( response.data );
                callback( response.data );
            }, ( error ) =>
            {
                callback( null );
                console.log( "Server error: " + error );
            } );
    },
    getConsumedKWHByConsumer ( selectedConsumer: string, callback: ( kWH: number ) => void )
    {
        console.log( selectedConsumer );
        api.get( '/api/app/docking-station/consumed-energy/?consumer=' + selectedConsumer )
            .then( ( response ) =>
            {
                callback( response.data );
            }, ( error ) =>
            {
                callback( 0 );
                console.log( "Server error: " + error );
            } );
    },
    getConsumedKWHOnUnit ( unitId: string, callback: ( kWH: number ) => void )
    {
        console.log( unitId );
        api.get( '/api/app/docking-station/consumed-energy/?unit=' + unitId )
            .then( ( response ) =>
            {
                callback( response.data );
            }, ( error ) =>
            {
                callback( 0 );
                console.log( "Server error: " + error );
            } );
    },
    openUnit ( unitId: string, callback: ( success: boolean, message: string ) => void )
    {
        api.post( '/api/app/docking-station/open-lock-by-id/' + unitId )
            .then( ( response ) =>
            {
                console.log( "opened " + response.statusText );
                callback( response.data, response.data ? "Unit opened successfully!" : "Unit couldn't been opened!" );
            }, ( error ) =>
            {
                callback( false, "Server error while trying to open unit!" );
                console.log( "Server error: " + error );
            } );
    },
    closeUnit ( unitId: string, callback: ( success: boolean, message: string ) => void )
    {
        api.post( '/api/app/docking-station/close-lock-by-id/' + unitId )
            .then( ( response ) =>
            {
                console.log( "closed " + response.statusText );
                callback( response.data, response.data ? "Unit closed successfully!" : "Unit couldn't been open!" );
            }, ( error ) =>
            {
                callback( false, "Server error while trying to close unit!" );
                console.log( "Server error: " + error );
            } );
    }
};
export enum Statuses
{
    fault = "Fault",
    occupied = "Occupied",
    free = "Free"
}
export type SUStatusesByDS = {
    [ key: string ]: Statuses;
};
export type DockingStationType = {
    id: string,
    dockingStationId: string,
    title: string,
    type: string,
    active?: boolean,
    terminal: [
        {
            nameOfPlace: string,
            unitsId: string[],
            terminalLocation: {
                latitude: number,
                longitude: number;
            },
        }
    ];
    consumersId: string[];
};