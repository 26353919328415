import React, { useState } from 'react';
import Button from './button';
import colors from './colors';
import Input from './input';
import '../css/adduser.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth';
import Select from './select';
import Progress from './progress';


function AddUser ()
{
    let [ errorDisplayedName, setErrorDisplayedName ] = useState( false );
    let [ errorEmailUnavailable, setErrorEmailUnavailable ] = useState( false );
    let [ errorEmail, setErrorEmail ] = useState( false );
    let [ errorPassword, setErrorPassword ] = useState( false );
    let [ errorRePassword, setErrorRePassword ] = useState( false );
    let [ formValid, setFormValid ] = useState( false );
    let [ progress, setProgress ] = useState( false );
    let [ didMount, setDidMount ] = useState( false );

    let navigate = useNavigate();
    let auth = useAuth();
    let privilege = [ { value: 'user', title: 'user' }, { value: 'admin', title: 'admin' } ];
    React.useEffect( () => { setDidMount( true ); }, [] );  // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect( () =>
    {
        if ( didMount )
        {
            setFormValid( !errorDisplayedName && !errorEmail );
        }
    }, [ errorDisplayedName, errorEmail ] );  // eslint-disable-line react-hooks/exhaustive-deps


    function handleSubmit ( event: React.FormEvent<HTMLFormElement> )
    {
        event.preventDefault();

        let formData = new FormData( event.currentTarget );
        let email = formData.get( "email" ) as string;
        let privilege = formData.get( "privilege" ) as string;
        let displayedName = formData.get( "displayedName" ) as string;
        let password = formData.get( 'password' ) as string;
        let repassword = formData.get( 'repassword' ) as string;

        checkDisplayedName( displayedName );
        checkEmail( email );
        checkPassword( password );
        if ( repassword !== password )
        {
            setErrorRePassword( true );
        }

        if ( formValid )
        {
            setProgress( true );
            auth.addUser( displayedName, privilege, email, password, callback );
        }
    }


    function callback ( added: boolean )
    {
        setProgress( false );
        if ( added )
        {
            navigate( "/user/" + auth.user.id, { replace: true } );
            alert( "User added" );
        } else
        {
            alert( "Server error, try again later!" );
        }
    }

    function checkDisplayedName ( displayedName: string )
    {
        setErrorDisplayedName( ( displayedName.length < 3 ) );
    }

    function checkEmail ( email: string )
    {
        let valid = email.match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
        if ( valid )
        {
            auth.emailAvailable( email, ( available ) =>
            {
                setErrorEmail( !available );
                setErrorEmailUnavailable( !available );
            } );
        } else
        {
            setErrorEmail( true );
        }
    }

    function checkPassword ( password: string )
    {
        let valid = password.match( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/ );
        if ( valid && password.length >= 6 )
        {
            setErrorPassword( false );
        } else
        {
            setErrorPassword( true );
        }
    }

    function checkRePassword ( currentTarget )
    {
        let formData = new FormData( currentTarget.form );
        let password = formData.get( 'password' ) as string;
        if ( currentTarget.value === password )
        {
            setErrorRePassword( false );
        } else
        {
            setErrorRePassword( true );
        }
    }

    return (
        <div className='add-user'>
            { progress ? <div className='progress-container'><Progress /></div> : null }
            <h2>Add user</h2>
            <form className='login' onSubmit={ handleSubmit }>
                <Input
                    label="Email address"
                    name="email"
                    width="200px"
                    color={ colors.green }
                    onChange={ ( e ) => { checkEmail( e.currentTarget.value ); } }
                    error={ errorEmail }
                    errorMessage={ errorEmailUnavailable ? "Email is already registered" : "Email address not valid!" } />
                <Input
                    label="Full name"
                    name="displayedName"
                    width="200px"
                    color={ colors.green }
                    onChange={ ( e ) => { checkDisplayedName( e.currentTarget.value ); } }
                    error={ errorDisplayedName }
                    errorMessage="Short full name (min 3 characters)!" />
                <Input
                    label="Password"
                    name="password"
                    width="200px"
                    color={ colors.green }
                    onChange={ ( e ) => { checkPassword( e.currentTarget.value ); } }
                    error={ errorPassword }
                    errorMessage="Password must contains an uppercase character, a lowercase character, a number, and a special character! Minimum 6 characters! (Example: Abc123*)" />
                <Input
                    label="Password again"
                    name="repassword"
                    width="200px"
                    color={ colors.green }
                    onChange={ ( e ) => { checkRePassword( e.currentTarget ); } }
                    error={ errorRePassword }
                    errorMessage="Passwords not match!" />
                <Select type="select" options={ privilege } label="Privilege" name="privilege" width="200px" color={ colors.green } />
                <Button type="submit" text="Sign up" color={ colors.green } />
                <Button type="button" className='back-button' text="Back" color={ colors.ligthgray } onClick={ () => navigate( "/user", { replace: true } ) } />
            </form>

        </div>
    );
}

export default AddUser;