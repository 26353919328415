import { UserType } from "src/components/auth";
import api, { getCookie } from "src/utils/api";

async function getUser ( userName: string, callback: ( user: UserType ) => void )
{
  let u: UserType = {
    id: null,
    email: null,
    displayedName: null,
    profilePicture: null,
    privilege: null,
    isActive: false
  };
  const userinfoResponse = await api.get( '/api/identity/users/by-username/' + userName );
  const privilegeinfoResponse = await api.get( '/api/identity/users/' + userinfoResponse.data.id + '/roles' );

  u.id = userinfoResponse.data.id;
  u.displayedName = userinfoResponse.data.surname ? userinfoResponse.data.surname + " " + userinfoResponse.data.name : userinfoResponse.data.name;
  u.email = userinfoResponse.data.email;
  u.privilege = privilegeinfoResponse.data.items[ 0 ].name;
  callback( u );
}
const authProvider = {
  isAuthenticated ( callback: ( auth: boolean ) => void )
  {
    callback( getCookie( 'idsrv.session' ).length > 1 );
  },
  adduser ( displayedName: string, privilege: string, email: string, password: string, callback: ( success: boolean ) => void )
  {
    console.log( "New user: " + email + " " + displayedName + " " + privilege );
    api.post( "/api/identity/users",
      JSON.stringify( {
        "userName": email.split( "@" )[ 0 ],
        "name": displayedName,
        "surname": null,
        "email": email,
        "phoneNumber": null,
        "isActive": true,
        "lockoutEnabled": true,
        "roleNames": [
          privilege
        ],
        "password": password
      } )
    ).then( ( response ) =>
    {
      let data = response.data;
      console.log( "User added with " + email + " data:" + data );
      callback( true );
    },
      ( error ) =>
      {
        callback( false );
        console.log( "Server error: " + error );
      } );
  },

  getUsers ( callback: ( users: UserType[] ) => void )
  {
    api.get( '/api/identity/users' )
      .then( ( response ) =>
      {
        let data = response.data;
        console.log( data.totalCount + " users' information recieved!" );
        let users: UserType[] = [];
        data.items.map( ( user: any ) =>
        {
          let u: UserType = {
            id: user.id,
            email: user.email,
            displayedName: user.surname ? user.surname + " " + user.name : user.name,
            profilePicture: null,
            privilege: null,
            isActive: user.isActive
          };
          console.log( "userinfo " + user.userName );
          users.push( u );
          return u;
        } );
        callback( users );
        console.log( "startcallback" );
      } ).catch( ( error ) =>
      {
        console.log( error.statusText );
      } );
  },

  updateUser ( name: string, callback: ( success: boolean ) => void )
  {
    api.put( '/api/account/my-profile', JSON.stringify( {
      "name": name
    } )
    ).then( ( response ) =>
    {
      let data = response.data;
      callback( data );
      console.log( "server response: " + data );
    },
      ( error ) =>
      {
        console.log( "Server error: " + error );
      } );
  },

  userInfo ( callback: ( data ) => void )
  {
    api.get( '/api/account/my-profile' )
      .then( ( response ) =>
      {
        getUser( response.data.userName, callback );
      },
        ( error ) =>
        {
          console.log( "Server error: " + error );
        } );
  },

  getUserRole ( id: string, callback: ( role ) => void )
  {
    api.get( '/api/identity/users/' + id + "/roles" )
      .then( ( response ) =>
      {
        if ( response.data.items )
        {
          console.log( "role " + response.data.items[ 0 ].name );
          callback( response.data.items[ 0 ].name );
        } else
        {
          callback( null );
        }
      } );
  },

  getUserByEmail ( email: string, callback: ( user ) => void )
  {
    api.get( '/api/identity/users/by-email/' + email.replace( "@", "%40" ) )
      .then( ( response ) =>
      {
        callback( response.data );
      } );
  },

  logout ( callback: ( success: boolean ) => void )
  {
    api.get( '/api/account/logout' )
      .then( () =>
      {
        callback( true );
      } )
      .catch( ( error ) =>
      {
        console.log( "Server error: " + error );
      } );
  },

  login ( emailOrUsername: string, password: string, callback: ( success: boolean ) => void )
  {
    api.post( '/api/account/login', JSON.stringify( {
      "userNameOrEmailAddress": emailOrUsername,
      "password": password,
      "rememberMe": false
    } ) ).then( ( response ) =>
    {
      if ( response.data.result === 1 )
      {

        callback( true );
      } else
      {
        callback( false );
      }
    },
      ( error ) =>
      {
        callback( false );
        console.log( "Server error: " + error );
      } );
  }
};

export { authProvider };
