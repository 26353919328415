import TopNavBar from "./topnavbar";
import Progress from "./progress";
import { useAuth } from "./auth";
import { useEffect, useState } from "react";
import { DockingStationType, Statuses, SUStatusesByDS } from "src/utils/dataprov";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLocationPin, faTerminal, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Button from "./button";
import colors from "./colors";
import "../css/search.css";
import AutoCompleteSelect from "./autocompleteselect";
import Unit from "./unit";

type FilterType = {
    station: string,
    terminal: string,
    unit: string;
};

function Search ()
{
    let [ dockingStations, setDockingStations ] = useState<DockingStationType[]>( [] );
    let [ allConsumer, setAllConsumer ] = useState( [] );
    let [ consumedOnUnit, setConsumedOnUnit ] = useState( 0 );
    let [ selectedConsumer, setSelectedConsumer ] = useState( null );
    let [ statuses, setStatuses ] = useState<SUStatusesByDS>( null );
    let [ filter, setFilter ] = useState<FilterType>( { station: "", terminal: "", unit: "" } );
    let auth = useAuth();
    let [ dataLoaded, setDataLoaded ] = useState( false );

    useEffect( () =>
    {
        getDockingStations();
    }, [] );  // eslint-disable-line react-hooks/exhaustive-deps

    function getDockingStations ()
    {
        auth.getDockingStations( ( list ) =>
        {
            setDockingStations( list );
        } );
    }

    useEffect( () =>
    {
        if ( dockingStations?.length > 0 )
        {
            setDataLoaded( true );
        }
        let consumerIds = [];
        dockingStations.forEach( ( station ) => station.consumersId.forEach( ( id ) => consumerIds.push( { id: id, title: id } ) ) );
        setAllConsumer( consumerIds );
    }, [ dockingStations ] );

    const changeStation = ( id ) =>
    {
        getStationUnitStatus( id );
        setFilter( { station: id, terminal: "", unit: "" } );
    };
    function getStationUnitStatus ( id )
    {
        auth.getStationUnitStatus( id, ( statuses ) =>
        {
            setStatuses( statuses );
        } );
    }
    const changeTerminal = ( id ) =>
    {
        setFilter( { station: filter.station, terminal: id, unit: "" } );
    };
    function changeUnit ( unitId )
    {
        setFilter( { station: filter.station, terminal: filter.terminal, unit: unitId } );
        auth.getConsumedKWHOnUnit( unitId, ( kWH ) =>
        {
            setConsumedOnUnit( kWH );
        } );
    }
    function changeStatus ( unitId, status )
    {
        console.log( "change status from" + status );

        setStatuses( { ...statuses, [ unitId ]: status } );
    }
    function getTerminals ()
    {
        let terminals = [];
        if ( filter.station )
        {
            terminals = dockingStations.find( x => x.id === filter.station )?.terminal?.map( ( i ) => { return { id: i.nameOfPlace, title: i.nameOfPlace }; } );
            console.log( terminals );
        }
        return terminals;
    }
    function changeConsumer ( id )
    {
        auth.getConsumedKWHByConsumer( id, ( kWH ) =>
        {
            setSelectedConsumer( kWH );
        } );
    }

    if ( !dataLoaded )
    {
        return ( <><Progress /></> );
    } else
    {
        return (
            <>
                <TopNavBar children={ <h2>
                    { filter.station ? dockingStations.find( x => x.id === filter.station )?.title : "Search" }
                    { filter.terminal &&
                        <FontAwesomeIcon icon={ faArrowRight as IconProp } className='icon' fixedWidth /> }
                    { filter.terminal && dockingStations.find( x => x.id === filter.station )?.terminal.find( x => x.nameOfPlace === filter.terminal )?.nameOfPlace }
                </h2> } />
                <div className='main'>

                    <AutoCompleteSelect label="KWH by consumer" type="inline" className="consumer-kwh" name="consumerKWH" key="consumerKWH" width="200px" options={ allConsumer }
                        changeCallback={ changeConsumer } icon={ <FontAwesomeIcon icon={ faUserCircle as IconProp } className='icon' fixedWidth /> } />
                    { selectedConsumer &&
                        <div className="consumer-kwh">
                            { selectedConsumer } kWH
                        </div>
                    }
                    <hr className="dotted"></hr>
                    <AutoCompleteSelect label="Station" type="inline" name="station" key="station" width="200px" options={ dockingStations.map( ( i ) => { return { id: i.id, title: i.title }; } ) }
                        changeCallback={ changeStation } icon={ <FontAwesomeIcon icon={ faLocationPin as IconProp } className='icon' fixedWidth /> } />


                    <AutoCompleteSelect label="Terminal" type="inline" name="terminal" key={ filter.station } width="200px" options={ getTerminals() }
                        changeCallback={ changeTerminal } icon={ <FontAwesomeIcon icon={ faTerminal as IconProp } className='icon' fixedWidth /> } />

                    <div className="station-units-wrap">
                        { filter.terminal &&
                            dockingStations.find( x => x.id === filter.station )?.terminal.find( x => x.nameOfPlace === filter.terminal ).unitsId.map(
                                ( unit, index ) =>
                                {
                                    let color: colors;
                                    switch ( statuses[ unit ] )
                                    {
                                        case Statuses.fault:
                                            color = colors.red;
                                            break;
                                        case Statuses.free:
                                            color = colors.green;
                                            break;
                                        case Statuses.occupied:
                                            color = colors.ligthgray;
                                            break;
                                        default:
                                            color = colors.white;
                                    }
                                    return (
                                        <div className="station-unit">
                                            <Button key={ unit } id={ unit } className={ filter.unit === unit ? "large" : "" } text={ ( index + 1 ).toString() } color={ color } onClick={ ( e ) => { changeUnit( e.currentTarget.id ); } } />
                                        </div>
                                    );
                                }
                            )
                        }
                    </div>

                    <hr className="dotted"></hr>
                    { filter.unit &&
                        <div className="consumed-on-unit">
                            Total consumed by unit: { consumedOnUnit } kWH
                        </div>
                    }
                    <hr className="dotted"></hr>
                    { filter.unit &&
                        <Unit key={ filter.unit }
                            stationUnitId={ filter.unit }
                            status={ statuses[ filter.unit ] }
                            consumers={ dockingStations.find( x => x.id === filter.station ).consumersId.map( ( id ) => { return { id: id, title: id }; } ) }
                            callback={ changeStatus } />
                    }
                </div>
            </>
        );
    }
}


export default Search;